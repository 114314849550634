// AicUi
// [note]
// hiroshima-cvbなどで用いているAicUiSyncクラスから、本件に必要のないものを省いた状態で流用。
// railsを使った場合の、コース検索後のパーツ生成のロジックを
// smmtが把握していないため、そのあたりの補助関数はとりあえず全部入りの状態で移植
//
// [note2]
// wp版(hiroshima-cvbなど)とrails版との大きな違いは、以下
// - WP版はコース結果をリクエストする先が2つある
// - WP版はコース結果htmlタグをJSで生成している

import axios from 'axios';
import Rails from '@rails/ujs';

export default class AicUi {
  constructor(opts = {}) {
    if (!opts.styles) opts.styles = {};
    if (!opts.elms) opts.elms = {};
    if (!opts.chartParams) opts.chartParams = {};
    if (!opts.contentsConfig) opts.contentsConfig = {};
    this.version = '1.0.0';
    // api
    this.apikey = opts.apikey || '';
    this.place = opts.place || 'tokyo';
    this.aicAPIBaseURI = opts.aicAPIBaseURI || 'https://jc-cmn-api-03.machi-pla.com/api_v1/best_plans.json';
    this.defaultLang = opts.defaultLang || 'en';
    this.crLang = opts.crLang || 'en';
    // elements
    this.elms = {
      // form parts
      startSpotIdSelect: document.querySelector('[data-role="aic-start-spot-selector"]'),
      durationSelect: document.querySelector('[data-role="aic-duration-selector"]'),
      startTimeSelect: document.querySelector('[data-role="aic-start-time-selector"]'),
      daysSelect: document.querySelector('[data-role="aic-days-selector"]'),
      transportationSelect: document.querySelector('[data-role="aic-transportation-selector"]'),
      sliders: document.querySelectorAll('[data-role="aic-taste-slider"]'),
      radarChartCanvas: document.querySelector('[data-role="aic-radar-chart"]'),
      radarBtns: document.querySelectorAll('[data-role="aic-radar-btn"]'),
      submitBtn: document.querySelector('[data-role="aic-submit-btn"]'),
      // status parts
      loading: document.querySelector('[data-role="aic-loading"]'),
      noItemMsg: document.querySelector('[data-role="aic-no-item-msg"]'),
      // result contents parts
      contents: document.querySelector('[data-role="aic-contents"]'),
      resultMsg: document.querySelector('[data-role="aic-result-msg"]'),
      resultBtnsArea: document.querySelector('[data-role="aic-result-btns-area"]'),
      copyBtn: document.querySelector('[data-role="aic-copy-btn"]'),
    };
    // [note] イレギュラー項目 (コロナ対策のラジオボタン)
    // this.covid19CheckedRadioSelectorName = '[data-role="aic-covid19-radio"]';
    // design / view
    this.hideClassName = opts.hideClassName || 'd-none';
    this.styles = {
      wrapBgColor: opts.styles.wrapBgColor || '#fff',
      radarInnerFillColor: opts.styles.radarInnerFillColor || '#f90',
      raderOutLineFillColor: opts.styles.raderOutLineFillColor || '#ccc',
      textFontFamily:
        opts.styles.textFontFamily ||
        "11px Helvetica, Arial, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', MS PGothic, sans-serif",
      textFill: opts.styles.textFill || '#000',
      textAlign: opts.styles.textAlign || 'center',
    };
    this.chartParams = {
      vals: opts.chartParams.vals || [0.2, 0.2, 0.2, 0.2, 0.2],
      range: 0.2,
      targetAxis: -1,
      labels: opts.chartParams.labels || {
        en: ['Ａ.Experience', 'Ｂ.Culture', 'Ｃ.Shopping', 'Ｄ.Art', 'Ｅ.Nature'],
        ja: ['Ａ.体験', 'Ｂ.歴史', 'Ｃ.ショッピング', 'Ｄ.アート', 'Ｅ.自然'],
        'zh-tw': ['Ａ.體驗', 'Ｂ.文化', 'Ｃ.購物', 'Ｄ.購物', 'Ｅ.自然'],
        th: ['Ａ.ประสบการณ์', 'Ｂ.วัฒนธรรม', 'Ｃ.ช้อปปิ้ง', 'Ｄ.ศิลป', 'Ｅ.ธรรมชาติ'],
      },
    };
    // listener
    Array.prototype.slice.call(this.elms.radarBtns, 0).forEach((btn) => {
      btn.addEventListener('click', (e) => {
        this.hundleRadarBtnClick(e);
      });
    });
    this.elms.submitBtn.addEventListener('click', (e) => {
      this.hundleSubmitBtnClick(e);
      return false;
    });
    this.elms.radarChartCanvas.addEventListener('mousedown', (e) => {
      e.preventDefault();
      this.hundleRadarMouseDown(e);
    });
    this.elms.radarChartCanvas.addEventListener('touchstart', (e) => {
      e.preventDefault();
      this.hundleRadarMouseDown(e);
    });
    this.elms.radarChartCanvas.addEventListener('mousemove', (e) => {
      e.preventDefault();
      this.hundleRadarMouseMove(e);
    });
    this.elms.radarChartCanvas.addEventListener('touchmove', (e) => {
      e.preventDefault();
      this.hundleRadarMouseMove(e);
    });
    this.elms.radarChartCanvas.addEventListener('mouseup', (e) => {
      e.preventDefault();
      this.hundleRadarMouseUp(e);
    });
    this.elms.radarChartCanvas.addEventListener('touchend', (e) => {
      e.preventDefault();
      this.hundleRadarMouseUp(e);
    });
  }

  // ------------------------------
  // init & handler
  // ------------------------------

  init() {
    // RadarChart内の項目などの初期化
    this.showProfile();
  }

  hundleRadarMouseDown(e) {
    // [note] targetAxisを更新する作業
    // if (e.originalEvent.changedTouches) e = e.originalEvent.changedTouches[0];
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const rectTop = this.elms.radarChartCanvas.getBoundingClientRect().top + scrollTop;
    const rectLeft = this.elms.radarChartCanvas.getBoundingClientRect().left + scrollLeft;
    const cW = this.elms.radarChartCanvas.offsetWidth; // キャンバス横サイズ
    const cH = this.elms.radarChartCanvas.offsetHeight; // キャンバス縦サイズ
    const angle = (Math.atan2(e.pageX - rectLeft - cW / 2, rectTop + cH / 2 - e.pageY) + 2 * Math.PI) % (2 * Math.PI);
    const degree = (angle * this.chartParams.vals.length) / (2 * Math.PI);
    if (degree % 1 <= 0.4 || degree % 1 >= 0.6) {
      this.chartParams.targetAxis = Math.round(degree) % this.chartParams.vals.length;
    }
  }

  hundleRadarMouseMove(e) {
    if (this.chartParams.targetAxis >= 0) this.setRadarChart(e);
  }

  hundleRadarMouseUp(e) {
    if (this.chartParams.targetAxis < 0) return;
    this.setRadarChart(e);
    this.chartParams.targetAxis = -1;
  }

  hundleRadarBtnClick(e) {
    const self = e.currentTarget;
    const cat = self.dataset.cat;
    const vector = self.dataset.vector;
    this.changeProfileWithCatAndVector(this.chartParams.range, cat, vector);
  }

  // [note] for ooshima-san 対応箇所はこのメソッド内に限定されます
  hundleSubmitBtnClick() {
    this.updateForGaVals();

    const requestURI = `${this.aicAPIBaseURI}?apikey=${this.apikey}&place=${
      this.place
    }${this.getRequestParamsForAic()}`;

    // [note] この値を使ってリクエストします↓
    console.log(requestURI);

    // [note] まずリクエスト開始前にローディングぐるぐるパーツを表示します。
    // ※ jsで制御していますが仕様変更必要ならお願いします
    this.showLoading();

    axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
    axios.interceptors.request.use((config) => ({
      ...config,
      headers: { ...config.headers, 'X-CSRF-Token': Rails.csrfToken() },
    }));

    // [note] リクエストしています
    axios
      .get(requestURI)
      .then((res) => {
        // [note] リクエストが成功した場合はこのthenブロックが実行されます
        // 正しくリクエストを設定するにはapp/javascript/packs/base.jsの
        // AicUiをnewしているところで、apikey, place, aicAPIBaseURIを正しく設定し直してください。
        //
        // [note] リクエストして取得できるデータは以下。これを使って結果タグ(html)を生成してください
        var result = document.getElementById('aic_search_result');
        result.innerHTML = res.data;

        this.aiRoutePlannerSubmitBtnScroller.scroll();

        // [note] ローディングぐるぐるの必要がなくなったので本パーツを消します。
        // ※ jsで制御していますが仕様変更必要ならお願いします
        this.hideLoading();
      })
      .catch((err) => {
        console.log(`${err}`);
        return null;
      });
  }

  aiRoutePlannerSubmitBtnScroller = {
    targetElmIdName: 'aic_search_result',
    yOffset: -150,
    animInterval: 15,
    stepCount: 1,
    stepNum: 20,
    scroll: function () {
      document.documentElement.scrollTop = 0;
      var targetElmYloc = document.getElementById(this.targetElmIdName).getBoundingClientRect().top;
      var stepCount = this.stepCount;
      var stepNum = this.stepNum;
      var distance = targetElmYloc + this.yOffset;
      var scrollNode = setInterval(function () {
        if (stepCount <= stepNum) {
          window.scrollBy(0, distance / stepNum);
          stepCount++;
        } else {
          clearInterval(scrollNode);
        }
      }, this.animInterval);
    },
  };

  // ------------------------------
  // Operate View
  // ------------------------------

  // [note] Google Tag Manager用に一部値をdata属性としてフロントに吐く
  updateForGaVals() {
    const params = this.getRequestParamsForAic({ includeSecretParams: false });
    this.elms.submitBtn.dataset.gaAicSubmitQueries = params;
    // GTA用の値は値を0〜10に変換して渡す
    // [note] 実際の値と多少ズレる結果となる
    const paramsForGTA = this.chartParams.vals.map((param) => Math.round(param * 100) / 10);
    this.elms.submitBtn.dataset.gaAicPref = paramsForGTA.toString();
  }

  updateView() {
    this.resetView();

    if (this.fetchingData.webSpots !== null && this.fetchingData.aic !== null) {
      this.showView();
    } else if (this.fetchingData.aic.status.code !== 0) {
      this.showNoItemView();
    } else {
      console.log('Error: courseData is incomplete');
    }
  }

  resetView() {
    this.hideNoItemMsg();
    this.hideResultUtilPartsTags();
    this.removeContentsTags();
  }

  showView() {
    this.showResultUtilPartsTags();
    this.generateContentsDataForView();
    this.showContentsTags(
      this.aicUiResultTags.getTags({
        contentsData: this.contentsData,
        lang: this.defaultLang,
      })
    );
  }

  showNoItemView() {
    this.showNoItemMsg();
  }

  showContentsTags(tags) {
    this.elms.contents.innerHTML = tags;
  }

  removeContentsTags() {
    this.elms.contents.innerHTML = '';
  }

  showResultUtilPartsTags() {
    this.elms.resultMsg.classList.remove(this.hideClassName);
    this.elms.resultBtnsArea.classList.remove(this.hideClassName);
  }

  hideResultUtilPartsTags() {
    this.elms.resultMsg.classList.add(this.hideClassName);
    this.elms.resultBtnsArea.classList.add(this.hideClassName);
  }

  showLoading() {
    this.elms.loading.classList.remove(this.hideClassName);
  }

  hideLoading() {
    this.elms.loading.classList.add(this.hideClassName);
  }

  showNoItemMsg() {
    this.elms.noItemMsg.classList.remove(this.hideClassName);
  }

  hideNoItemMsg() {
    this.elms.noItemMsg.classList.add(this.hideClassName);
  }

  // ------------------------------
  // Operate data
  // ------------------------------

  getRequestParamsForAic(opts = {}) {
    const tasteArr = [];
    Array.prototype.slice.call(this.elms.sliders, 0).forEach((slider) => {
      tasteArr.push(Math.round(Number(slider.value)));
    });
    let params = '';
    if (opts.includeSecretParams) {
      params += `&apikey=${this.apikey}`;
      params += `&place=${this.place}`;
    }
    // params += `&_${new Date().getTime()}`;
    params += `&start_spot_id=${this.elms.startSpotIdSelect.value}`;
    params += `&duration=${this.elms.durationSelect.value}`;
    params += `&start_time=${this.elms.startTimeSelect.value}`;
    params += `&days=${this.elms.daysSelect.value}`;
    params += `&transportation=${this.elms.transportationSelect.value}`;
    params += `&taste=${tasteArr.toString()}`;
    params += `&pref=${this.chartParams.vals.toString()}`;
    // params += `&avoid_congestion=${document.querySelector(`${this.covid19CheckedRadioSelectorName}:checked`).value}`;
    return params;
  }

  // ------------------------------
  // Operate Form
  // ------------------------------

  // レーダーチャート: 初期化
  setRadarChart(e) {
    // if (e.originalEvent.changedTouches) e = e.originalEvent.changedTouches[0];
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const rectTop = this.elms.radarChartCanvas.getBoundingClientRect().top + scrollTop;
    const rectLeft = this.elms.radarChartCanvas.getBoundingClientRect().left + scrollLeft;
    const cW = this.elms.radarChartCanvas.offsetWidth; // キャンバス横サイズ
    const cH = this.elms.radarChartCanvas.offsetHeight; // キャンバス縦サイズ
    const rdSize = Math.min(cH / 2 - 15, cW / 2 - 25); // レーダーチャートのサイズ
    const vals = this.chartParams.vals;
    const angle = Math.atan2(e.pageX - rectLeft - cW / 2, rectTop + cH / 2 - e.pageY);
    let pos =
      (Math.sqrt((e.pageX - rectLeft - cW / 2) ** 2 + (rectTop + cH / 2 - e.pageY) ** 2) *
        Math.cos(angle - (this.chartParams.targetAxis / vals.length) * Math.PI * 2)) /
      rdSize;
    pos = Math.min(Math.max(pos, 0.2), 1);
    const pos2weight = (position) => ((position - 0.2) / 0.8) ** 2;
    for (let i = 0; i < vals.length; i += 1) {
      if (i !== this.chartParams.targetAxis)
        vals[i] =
          vals[this.chartParams.targetAxis] === 1
            ? (1 - pos2weight(pos)) / vals.length
            : (vals[i] * (1 - pos2weight(pos))) / (1 - vals[this.chartParams.targetAxis]);
    }
    vals[this.chartParams.targetAxis] = pos2weight(pos);
    this.showProfile();
  }

  // レーダーチャート: 描画
  drawRadarChart(canvas, val, label) {
    // 与えられたcanvasにレーダーチャートを描く（val，labelは値，ラベルの列）
    const cW = canvas.width; // キャンバス横サイズ
    const cH = canvas.height; // キャンバス縦サイズ
    const rdSize = Math.min(cH / 2 - 10, cW / 2 - 20);
    const ctx = canvas.getContext('2d');
    const n = val.length;
    // 消去
    ctx.fillStyle = this.styles.wrapBgColor;
    ctx.fillRect(0, 0, cW, cH);
    // レーダー内部
    ctx.fillStyle = this.styles.radarInnerFillColor;
    ctx.beginPath();
    ctx.moveTo(cW / 2, cH / 2 - rdSize * val[0]);
    for (let i = 1; i < n; i += 1) {
      ctx.lineTo(
        cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2) * val[i],
        cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2) * val[i]
      );
    }

    ctx.closePath();
    ctx.fill();
    // 外周・目盛り
    ctx.fillStyle = this.styles.raderOutLineFillColor;
    ctx.strokeStyle = this.styles.raderOutLineFillColor;
    ctx.beginPath();
    ctx.moveTo(cW / 2, cH / 2 - rdSize);
    for (let i = 1; i < n; i += 1) {
      ctx.lineTo(cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2), cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2));
    }
    ctx.closePath();
    ctx.stroke();
    for (let i = 0; i < n; i += 1) {
      ctx.beginPath();
      ctx.moveTo(cW / 2, cH / 2);
      ctx.lineTo(cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2), cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2));
      ctx.closePath();
      ctx.stroke();
      for (let j = 1; j <= 5; j += 1) {
        ctx.beginPath();
        ctx.arc(
          cW / 2 + (rdSize * Math.sin((i / n) * Math.PI * 2) * j) / 5,
          cH / 2 - (rdSize * Math.cos((i / n) * Math.PI * 2) * j) / 5,
          2,
          0,
          Math.PI * 2,
          true
        );
        ctx.fill();
      }
    }
    // ラベル
    ctx.font = this.styles.textFontFamily;
    ctx.textAlign = this.styles.textAlign;
    ctx.fillStyle = this.styles.textFill;
    for (let i = 0; i < n; i += 1) {
      ctx.fillText(
        label[i],
        cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2) * 1.3,
        cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2) * 1.1 + 5
      );
    }
  }

  // レーダーチャート: 項目内容の生成
  showProfile() {
    // 現在の嗜好を表示する
    const label = this.chartParams.labels[this.crLang]
      ? this.chartParams.labels[this.crLang]
      : this.chartParams.labels[this.defaultLang];
    const weight2pos = (weight) => Math.sqrt(weight) * 0.8 + 0.2;
    // レーダーチャートを描く
    const pos = [];
    for (let i = 0; i < this.chartParams.vals.length; i += 1) {
      pos.push(weight2pos(this.chartParams.vals[i]));
    }
    this.drawRadarChart(this.elms.radarChartCanvas, pos, label);
  }

  // レーダーチャート: プロファイルの増減処理
  changeProfile(index, num) {
    // 指定された現在のプロファイル値をチェック
    let tmpChgProfile = this.chartParams.vals[index] + num;
    if (tmpChgProfile > 1) {
      tmpChgProfile = 1;
    } else if (tmpChgProfile < 0) {
      tmpChgProfile = 0;
    }
    this.chartParams.vals[index] = tmpChgProfile;
    // 変更されなかったカテゴリに割り当てるウェイトの数値合計
    const totalPoint = 1 - this.chartParams.vals[index];
    // 変更されなかったカテゴリの割合値の合計
    let totalWeight = 0;
    for (let i = 0; i < this.chartParams.vals.length; i += 1) {
      if (i !== index) {
        totalWeight += this.chartParams.vals[i];
      }
    }
    for (let i = 0; i < this.chartParams.vals.length; i += 1) {
      if (i !== index) {
        if (totalWeight > 0) {
          this.chartParams.vals[i] = totalPoint * (this.chartParams.vals[i] / totalWeight);
        } else if (totalWeight <= 0) {
          this.chartParams.vals[i] = totalPoint * (1 / (this.chartParams.vals.length - 1));
        } else {
          this.chartParams.vals[i] = 0;
        }
      }
    }
    this.showProfile();
  }

  // レーダーチャート: ピンポイントに1つのパラメータを変更する(プラスマイナスボタンでの操作想定)
  changeProfileWithCatAndVector(changeNum, cat, vector) {
    const changeSize = vector === 'inc' ? changeNum : changeNum * -1;
    switch (cat) {
      case 'a':
        this.changeProfile(0, changeSize);
        break;
      case 'b':
        this.changeProfile(1, changeSize);
        break;
      case 'c':
        this.changeProfile(2, changeSize);
        break;
      case 'd':
        this.changeProfile(3, changeSize);
        break;
      case 'e':
        this.changeProfile(4, changeSize);
        break;
      default:
    }
  }
}
